<!--
 * @Description: 搜索模板配置
 * @Autor: WangYuan
 * @Date: 2021-06-10 19:31:01
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-09 20:29:49
-->
<template>
	<div class="wrap">

		<!-- 搜索展示 -->
		<div style="padding-right:360px">
			<phone-ctn>
				<search-bar></search-bar>
			</phone-ctn>
		</div>



		<!-- 搜索配置 -->
		<config-ctn title="搜索页设置">
			<div class="p10">
				<label class=" f12">此配置适用于----- 搜索页</label>

				<div class="nav">
					<div class="mt10 mb15 f12 f-red">
						<config-item label='按钮颜色'>
							<config-color-picker v-model="project.setting.search.btnBg"></config-color-picker>
						</config-item>
					</div>

					<!-- 					<div class="mt10 mb15 f12 f-red">
						<config-item label='登录按钮文字'>
							<el-input placeholder="请输入名字" v-model="project.setting.login.btnTitle" size="small"
								@change="handleChange"></el-input>
						</config-item>

						<config-item label='按钮背景颜色'>
							<config-color-picker v-model="project.setting.login.btnBgColor"></config-color-picker>
						</config-item>

						<config-item label='按钮高度(px,vw.%)'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnHeight"></el-input>
						</config-item>

						<config-item label='按钮宽度(px,vw.%)'>
							<el-input placeholder="请输入屏幕宽度的百分比,一般默认90" size="small" @change="handleChange"
								v-model="project.setting.login.btnWidth"></el-input>
						</config-item>

						<config-item label='按钮上边圆角'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnUpperRadius"></el-input>
						</config-item>

						<config-item label='按钮下边圆角'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnLowerRadius"></el-input>
						</config-item>

						<config-item label='按钮距上边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnMarginTop"></el-input>
						</config-item>

						<config-item label='按钮距下边间距'>
							<el-input placeholder="请输入数字" size="small" @change="handleChange"
								v-model="project.setting.login.btnMarginBottom"></el-input>
						</config-item>
					</div> -->




				</div>


			</div>
		</config-ctn>






	</div>
</template>

<script>
	import SearchBar from "@/components/SearchBar";
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "navigation",

		components: {
			SearchBar,
		},

		computed: {
			...mapGetters(["project"]),
		},
		mounted() {
			console.log(this.project.setting.search);
			if (this.project.setting.search == undefined) {
				this.project.setting.search = {
					btnBg: "#f0f0f0f"
				}

			}
		},

		onload() {

		},
		methods: {
			add() {
				this.project.config.hotList.push({
					value: ""
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.nav-item {
		padding: 10px;
		margin-bottom: 10px;
		border-radius: 2px;
		background-color: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}
</style>